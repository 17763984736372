/**
 * A list of valid deployment server environments
 */
export enum ServerEnvironment {
    DEMO = 'DEMO',
    SANDBOX = 'SANDBOX',
    STAGE = 'STAGE',
    UAT = 'UAT',
    CN_UAT = 'CN_UAT',
    DHL_UAT = 'DHL_UAT',
    SES = 'SES',
    SYDNEY = 'SYDNEY',
    CANADA = 'CANADA',
    FRANKFURT = 'FRANKFURT',
    IRELAND = 'IRELAND',
    TOKYO = 'TOKYO',
    SAO_PAULO = 'SAO_PAULO',
    SINGAPORE = 'SINGAPORE',
    UK = 'UK',
    MENA = 'MENA',
    PRODUCTION = 'PRODUCTION'
}

/**
 * A list of valid CI server environments
 */
export enum ServerCIEnvironment {
    ANY = 'ANY',
    DEMO = 'Demo',
    DEVELOPMENT = 'Development',
    PRODUCTION = 'Production',
    SANDBOX = 'Sandbox',
    SES = 'SES',
    STAGE = 'Stage',
    UAT = 'UAT',
    CN_UAT = 'workload-cn-uat-account',
    DHL_UAT = 'workload-dhl-account'
}

/**
 * Describes the struture of a `ServerBaseUrl` object
 */
export interface ServerBaseUrl {
    /**
     * The base url to use for all Wise API calls
     */
    apiServer: string;

    /**
     * The base url to use for all Wise Socket calls
     */
    socketServer: string;

    /**
     * The corresponding Gitlab environment name
     */
    CIEnv: ServerCIEnvironment;

    /**
     * The base url to use for all Initial-vs-Actual (IvA) microservice calls
     */
    initialVsActualServer?: string;

    /**
     * The base url to use for all Equipment microservice calls
     */
    equipment?: string;

    /**
     * The base url to use for all Equipment socket calls
     */
    equipmentSocketServer?: string;

    /**
     * The deployment environment
     */
    environment?: ServerEnvironment;

    /**
     * The base url to use for all Vehicle profile microservice calls
     */
    vehicleProfile?: string;

    /**
     * The base url to use for all Route Path Geometry microservice calls
     */
    routePathGeometryServer?: string;

    /**
     * The base url to use for all Configurable Workflow microservice calls
     */
    configurableWorkflowServer?: string;
}

/**
 * A list of valid `ServerBaseUrl` properties / keys
 */
export enum ServerBaseUrlKey {
    /**
     * The `ServerBaseUrl` property/key identifying the Wise API base url
     */
    API = 'apiServer',

    /**
     * The `ServerBaseUrl` property/key identifying the Wise Socket base url
     */
    SOCKET = 'socketServer',

    /**
     * The `ServerBaseUrl` property/key identifying the Initial-vs-Actual (IvA) microservice base url
     */
    IVA = 'initialVsActualServer',

    /**
     * The `ServerBaseUrl` property/key identifying the Equipment microservice base url
     */
    EQUIPMENT = 'equipment',

    /**
     * The `ServerBaseUrl` property/key identifying the Equipment socket base url
     */
    EQUIPMENT_SOCKET = 'equipmentSocketServer',

    /**
     * The `ServerBaseUrl` property/key identifying the deployment environment
     */
    ENVIRONMENT = 'environment',

    /**
     * The `ServerBaseUrl` property/key identifying the CI environment
     */
    CI_ENVIRONMENT = 'CIEnv',

    /**
     * The `ServerBaseUrl` property/key identifying the Vehicle profile microservice base url
     */
    VEHICLE_PROFILE = 'vehicleProfile',

    /**
     * The `ServerBaseUrl` property/key identifying the Route path geometry microservice base url
     */
    ROUTE_PATH_GEOMETRY = 'routePathGeometryServer',

    /**
     * The `ServerBaseUrl` property/key identifying the Configurable workflow microservice base url
     */
    CONFIGURABLE_WORKFLOW = 'configurableWorkflowServer'
}

/**
 * A mapping of Interstellar hostnames to various service server URLs
 */
export const endPointToServerMapping: Record<string, ServerBaseUrl> = {
    'admin.demo.wisesys.info': {
        apiServer: 'https://api.demo.wisesys.info',
        initialVsActualServer:
            'https://69g552rju8.execute-api.us-east-1.amazonaws.com/demo',
        socketServer: 'wss://demo.socket.wisesys.info',
        environment: ServerEnvironment.DEMO,
        CIEnv: ServerCIEnvironment.DEMO,
        vehicleProfile:
            'https://hgfk0t7kh3.execute-api.us-east-1.amazonaws.com/demo',
        routePathGeometryServer:
            'https://wnfltdovrb.execute-api.us-east-1.amazonaws.com/demo',
        configurableWorkflowServer:
            'https://zq3uoax4v9.execute-api.us-east-1.amazonaws.com/Prod',
        equipment: 'https://services-demo.us.wisesys.info'
    },
    'admin.dev.wisesys.info': {
        apiServer: 'https://wise-api.dev.wisesys.info',
        equipment: 'https://services-stage.us.wisesys.info',
        socketServer: 'wss://socket.dev.wisesys.info',
        equipmentSocketServer: 'wss://equipmentsocket-stage.us.wisesys.info',
        CIEnv: ServerCIEnvironment.DEVELOPMENT,
        vehicleProfile:
            'https://y2sdpvqnw0.execute-api.us-east-1.amazonaws.com/development',
        routePathGeometryServer:
            'https://0tc6grz4n9.execute-api.us-east-1.amazonaws.com/development',
        configurableWorkflowServer:
            'https://uu9d8rt95l.execute-api.us-east-1.amazonaws.com/Prod'
    },
    'admin.sandbox.wisesys.info': {
        apiServer: 'https://api.sandbox.wisesys.info',
        equipment: 'https://services-stage.us.wisesys.info',
        socketServer: 'wss://dev.socket.wisesys.info',
        equipmentSocketServer: 'wss://equipmentsocket-stage.us.wisesys.info',
        environment: ServerEnvironment.SANDBOX,
        CIEnv: ServerCIEnvironment.SANDBOX,
        vehicleProfile:
            'https://y2sdpvqnw0.execute-api.us-east-1.amazonaws.com/development',
        routePathGeometryServer:
            'https://0tc6grz4n9.execute-api.us-east-1.amazonaws.com/development',
        configurableWorkflowServer:
            'https://uu9d8rt95l.execute-api.us-east-1.amazonaws.com/Prod'
    },
    'admin.stage.wisesys.info': {
        apiServer: 'https://api.stage.wisesys.info',
        initialVsActualServer:
            'https://zl45ifbuj0.execute-api.us-east-1.amazonaws.com/stage',
        equipment: 'https://services-stage.us.wisesys.info',
        socketServer: 'wss://stage.socket.wisesys.info',
        equipmentSocketServer: 'wss://equipmentsocket-stage.us.wisesys.info',
        environment: ServerEnvironment.STAGE,
        CIEnv: ServerCIEnvironment.STAGE,
        vehicleProfile:
            'https://idlnziuvpk.execute-api.us-east-1.amazonaws.com/stage',
        routePathGeometryServer:
            'https://1k04l2v0j8.execute-api.us-east-1.amazonaws.com/stage',
        configurableWorkflowServer:
            'https://4wsqcxtrih.execute-api.us-east-1.amazonaws.com/Prod'
    },
    'admin.uat.wisesys.info': {
        apiServer: 'https://api.uat.wisesys.info',
        initialVsActualServer:
            'https://l8e0mz5odb.execute-api.us-east-1.amazonaws.com/uat',
        equipment: 'https://services-uat.us.wisesys.info',
        socketServer: 'wss://uat.socket.wisesys.info',
        equipmentSocketServer: 'wss://equipmentsocket-uat.us.wisesys.info',
        environment: ServerEnvironment.UAT,
        CIEnv: ServerCIEnvironment.UAT,
        vehicleProfile:
            'https://z9fgl5xulh.execute-api.us-east-1.amazonaws.com/uat',
        routePathGeometryServer:
            'https://u0dlfowfi9.execute-api.us-east-1.amazonaws.com/uat',
        configurableWorkflowServer:
            'https://ksjgorzu2k.execute-api.us-east-1.amazonaws.com/Prod'
    },
    'admin.cn-uat.wisesys.info': {
        apiServer: 'https://wise-api.cn-uat.wisesys.info',
        initialVsActualServer:
            'https://l8e0mz5odb.execute-api.us-east-1.amazonaws.com/uat',
        equipment: 'https://services.ca.cn-uat.wisesys.info',
        socketServer: 'wss://socket.cn-uat.wisesys.info',
        equipmentSocketServer: 'wss://equipmentsocket.ca.cn-uat.wisesys.info',
        environment: ServerEnvironment.CN_UAT,
        CIEnv: ServerCIEnvironment.CN_UAT,
        vehicleProfile:
            'https://z9fgl5xulh.execute-api.us-east-1.amazonaws.com/uat',
        routePathGeometryServer:
            'https://u0dlfowfi9.execute-api.us-east-1.amazonaws.com/uat',
        configurableWorkflowServer:
            'https://ksjgorzu2k.execute-api.us-east-1.amazonaws.com/Prod'
    },
    'admin.dhl-uat.wisesys.info': {
        apiServer: 'https://wise-api.dhl-uat.wisesys.info',
        initialVsActualServer:
            'https://g9ezx7baqc.execute-api.us-east-2.amazonaws.com/uat',
        socketServer: 'wss://socket.dhl-uat.wisesys.info',
        environment: ServerEnvironment.DHL_UAT,
        CIEnv: ServerCIEnvironment.DHL_UAT,
        vehicleProfile:
            'https://z9fgl5xulh.execute-api.us-east-1.amazonaws.com/uat',
        routePathGeometryServer:
            'https://u0dlfowfi9.execute-api.us-east-1.amazonaws.com/uat',
        configurableWorkflowServer:
            'https://ksjgorzu2k.execute-api.us-east-1.amazonaws.com/Prod'
    },
    'admin.ses.wisesys.info': {
        apiServer: 'https://wise-api.ses.wisesys.info',
        socketServer: 'wss://socket.ses.wisesys.info',
        environment: ServerEnvironment.SES,
        CIEnv: ServerCIEnvironment.SES,
        vehicleProfile:
            'https://z9fgl5xulh.execute-api.us-east-1.amazonaws.com/uat',
        routePathGeometryServer:
            'https://u0dlfowfi9.execute-api.us-east-1.amazonaws.com/uat',
        configurableWorkflowServer:
            'https://ksjgorzu2k.execute-api.us-east-1.amazonaws.com/Prod'
    },
    'enterprise.au.wisesystems.io': {
        apiServer: 'https://api.au.wisesys.info',
        socketServer: 'wss://au.socket.wisesys.info',
        environment: ServerEnvironment.SYDNEY,
        CIEnv: ServerCIEnvironment.PRODUCTION,
        vehicleProfile:
            'https://ybtfow4mv3.execute-api.us-east-1.amazonaws.com/production',
        routePathGeometryServer:
            'https://ol1vgxgyob.execute-api.us-east-1.amazonaws.com/production',
        configurableWorkflowServer:
            'https://zcqwzh2eue.execute-api.us-east-1.amazonaws.com/Prod'
    },
    'enterprise.ca.wisesystems.io': {
        apiServer: 'https://api.ca.wisesys.info',
        equipment: 'https://services.ca.wisesys.info',
        socketServer: 'wss://ca.socket.wisesys.info',
        equipmentSocketServer: 'wss://equipmentsocket.ca.wisesys.info',
        environment: ServerEnvironment.CANADA,
        CIEnv: ServerCIEnvironment.PRODUCTION,
        vehicleProfile:
            'https://ybtfow4mv3.execute-api.us-east-1.amazonaws.com/production',
        routePathGeometryServer:
            'https://ol1vgxgyob.execute-api.us-east-1.amazonaws.com/production',
        configurableWorkflowServer:
            'https://zcqwzh2eue.execute-api.us-east-1.amazonaws.com/Prod'
    },
    'enterprise.ce.wisesystems.io': {
        apiServer: 'https://api.ce.wisesys.info',
        socketServer: 'wss://ce.socket.wisesys.info',
        environment: ServerEnvironment.FRANKFURT,
        CIEnv: ServerCIEnvironment.PRODUCTION,
        vehicleProfile:
            'https://ybtfow4mv3.execute-api.us-east-1.amazonaws.com/production',
        routePathGeometryServer:
            'https://ol1vgxgyob.execute-api.us-east-1.amazonaws.com/production',
        configurableWorkflowServer:
            'https://zcqwzh2eue.execute-api.us-east-1.amazonaws.com/Prod'
    },
    'enterprise.ie.wisesystems.io': {
        apiServer: 'https://api.ie.wisesys.info',
        socketServer: 'wss://uk.socket.wisesys.info',
        environment: ServerEnvironment.IRELAND,
        CIEnv: ServerCIEnvironment.PRODUCTION,
        vehicleProfile:
            'https://ybtfow4mv3.execute-api.us-east-1.amazonaws.com/production',
        routePathGeometryServer:
            'https://ol1vgxgyob.execute-api.us-east-1.amazonaws.com/production',
        configurableWorkflowServer:
            'https://zcqwzh2eue.execute-api.us-east-1.amazonaws.com/Prod'
    },
    'enterprise.jp.wisesystems.io': {
        apiServer: 'https://api.jp.wisesys.info',
        socketServer: 'wss://jp.socket.wisesys.info',
        environment: ServerEnvironment.TOKYO,
        CIEnv: ServerCIEnvironment.PRODUCTION,
        vehicleProfile:
            'https://ybtfow4mv3.execute-api.us-east-1.amazonaws.com/production',
        routePathGeometryServer:
            'https://0q6k5or5lc.execute-api.ap-northeast-1.amazonaws.com/production',
        configurableWorkflowServer:
            'https://zcqwzh2eue.execute-api.us-east-1.amazonaws.com/Prod'
    },
    'enterprise.sa.wisesystems.io': {
        apiServer: 'https://api.sa.wisesys.info',
        socketServer: 'wss://sa.socket.wisesys.info',
        environment: ServerEnvironment.SAO_PAULO,
        CIEnv: ServerCIEnvironment.PRODUCTION,
        vehicleProfile:
            'https://ybtfow4mv3.execute-api.us-east-1.amazonaws.com/production',
        routePathGeometryServer:
            'https://ol1vgxgyob.execute-api.us-east-1.amazonaws.com/production',
        configurableWorkflowServer:
            'https://zcqwzh2eue.execute-api.us-east-1.amazonaws.com/Prod'
    },
    'enterprise.sg.wisesystems.io': {
        apiServer: 'https://api.sg.wisesys.info',
        socketServer: 'wss://sg.socket.wisesys.info',
        environment: ServerEnvironment.SINGAPORE,
        CIEnv: ServerCIEnvironment.PRODUCTION,
        vehicleProfile:
            'https://ybtfow4mv3.execute-api.us-east-1.amazonaws.com/production',
        routePathGeometryServer:
            'https://0q6k5or5lc.execute-api.ap-northeast-1.amazonaws.com/production',
        configurableWorkflowServer:
            'https://zcqwzh2eue.execute-api.us-east-1.amazonaws.com/Prod'
    },
    'enterprise.uk.wisesystems.io': {
        apiServer: 'https://api.uk.wisesys.info',
        socketServer: 'wss://uk.socket.wisesys.info',
        environment: ServerEnvironment.UK,
        CIEnv: ServerCIEnvironment.PRODUCTION,
        vehicleProfile:
            'https://ybtfow4mv3.execute-api.us-east-1.amazonaws.com/production',
        routePathGeometryServer:
            'https://ol1vgxgyob.execute-api.us-east-1.amazonaws.com/production',
        configurableWorkflowServer:
            'https://zcqwzh2eue.execute-api.us-east-1.amazonaws.com/Prod'
    },
    'enterprise.mena.wisesystems.io': {
        apiServer: 'https://api.mena.wisesys.info',
        socketServer: 'wss://mena.socket.wisesys.info',
        environment: ServerEnvironment.MENA,
        CIEnv: ServerCIEnvironment.PRODUCTION,
        vehicleProfile:
            'https://ybtfow4mv3.execute-api.us-east-1.amazonaws.com/production',
        routePathGeometryServer:
            'https://ol1vgxgyob.execute-api.us-east-1.amazonaws.com/production',
        configurableWorkflowServer:
            'https://zcqwzh2eue.execute-api.us-east-1.amazonaws.com/Prod'
    },
    'enterprise.wisesystems.io': {
        apiServer: 'https://api.wisesys.info',
        initialVsActualServer:
            'https://p8aowjzsqh.execute-api.us-east-1.amazonaws.com/production',
        equipment: 'https://services-production.us.wisesys.info',
        equipmentSocketServer:
            'wss://equipmentsocket-production.us.wisesys.info',
        socketServer: 'wss://prod.socket.wisesys.info',
        environment: ServerEnvironment.PRODUCTION,
        CIEnv: ServerCIEnvironment.PRODUCTION,
        vehicleProfile:
            'https://ybtfow4mv3.execute-api.us-east-1.amazonaws.com/production',
        routePathGeometryServer:
            'https://ol1vgxgyob.execute-api.us-east-1.amazonaws.com/production',
        configurableWorkflowServer:
            'https://zcqwzh2eue.execute-api.us-east-1.amazonaws.com/Prod'
    },
    'wise-multi-client:8033': {
        apiServer: 'http://wise-api:8002',
        equipment: 'https://services-stage.us.wisesys.info',
        socketServer: 'ws://wise-socket:1037',
        equipmentSocketServer: 'wss://equipmentsocket-stage.us.wisesys.info',
        CIEnv: ServerCIEnvironment.DEVELOPMENT,
        vehicleProfile:
            'https://y2sdpvqnw0.execute-api.us-east-1.amazonaws.com/development',
        routePathGeometryServer:
            'https://0tc6grz4n9.execute-api.us-east-1.amazonaws.com/development',
        configurableWorkflowServer:
            'https://uu9d8rt95l.execute-api.us-east-1.amazonaws.com/Prod'
    },
    development: {
        apiServer: 'http://localhost:8002',
        initialVsActualServer:
            'https://ltwm53t685.execute-api.us-east-1.amazonaws.com/development',
        equipment: 'https://services-stage.us.wisesys.info',
        socketServer: 'ws://localhost:1037',
        equipmentSocketServer: 'wss://equipmentsocket-stage.us.wisesys.info',
        CIEnv: ServerCIEnvironment.DEVELOPMENT,
        vehicleProfile:
            'https://y2sdpvqnw0.execute-api.us-east-1.amazonaws.com/development',
        routePathGeometryServer:
            'https://0tc6grz4n9.execute-api.us-east-1.amazonaws.com/development',
        configurableWorkflowServer:
            'https://uu9d8rt95l.execute-api.us-east-1.amazonaws.com/Prod'
    },
    sandbox: {
        apiServer: 'https://api.sandbox.wisesys.info',
        equipment: 'https://services-stage.us.wisesys.info',
        socketServer: 'wss://dev.socket.wisesys.info',
        equipmentSocketServer: 'wss://equipmentsocket-stage.us.wisesys.info',
        CIEnv: ServerCIEnvironment.SANDBOX,
        vehicleProfile:
            'https://y2sdpvqnw0.execute-api.us-east-1.amazonaws.com/development',
        routePathGeometryServer:
            'https://0tc6grz4n9.execute-api.us-east-1.amazonaws.com/development',
        configurableWorkflowServer:
            'https://uu9d8rt95l.execute-api.us-east-1.amazonaws.com/Prod'
    }
};

/**
 * Retrieves the base url for the target server
 *
 * @param {ServerBaseUrlKey} server - the target server key to retrieve from the mapping
 * @returns {string | undefined}
 */
export const getURL = (server: ServerBaseUrlKey) => {
    const { hostname: endPoint } = window.location;
    const isSandbox =
        endPoint.match(/.*\.wisesys\.info/) ||
        endPoint.match(/^wisesystems\.gitlab\.io\/.*/);
    const {
        development: { [server]: devServer },
        sandbox: { [server]: sandboxServer },
        ...env
    } = endPointToServerMapping;
    const { [server]: envServer } = env[endPoint] || {};

    return envServer || (isSandbox && sandboxServer) || devServer;
};
